<template>
  <div class="container">
    <BaseHeader title="Gestão de domínios" :navigation="navigation">
    </BaseHeader>

    <div class="container-table">
      <div class="box-total" v-if="!loading">
        <div>
          <p class="total-vendas">
            Total de domínios:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div v-else>
        <b-skeleton
          width="100%"
          height="83px"
          style="border-radius: 10px"
        ></b-skeleton>
      </div>

      <div class="header-table" v-if="!loading">
        <BaseButton @click="reloadList" variant="primary" class="btn-reload">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="#fff"
            viewBox="0 0 256 256"
          >
            <path
              d="M200.49,183.51a12,12,0,0,1,0,17c-1.13,1.12-28,27.51-72.49,27.51-33.71,0-59.35-17.46-76-33.86V208a12,12,0,0,1-24,0V160a12,12,0,0,1,12-12H88a12,12,0,0,1,0,24H64.12c13.1,14.32,35.08,32,63.88,32,34.63,0,55.31-20.28,55.51-20.49A12,12,0,0,1,200.49,183.51ZM216,36a12,12,0,0,0-12,12V61.86C187.35,45.46,161.71,28,128,28,83.54,28,56.64,54.39,55.51,55.51a12,12,0,0,0,17,17C72.69,72.28,93.37,52,128,52c28.8,0,50.78,17.68,63.88,32H168a12,12,0,0,0,0,24h48a12,12,0,0,0,12-12V48A12,12,0,0,0,216,36Z"
            ></path>
          </svg>
        </BaseButton>

        <div style="display: flex; gap: 15px">
          <BaseButton
            v-if="!isMobile"
            class="nova-pagina instrucao"
            variant="primary"
            @click="openModalInstruction"
            :disabled="loading"
          >
            Instruções
          </BaseButton>
            <BaseButton
              v-if="!isMobile "
              class="nova-pagina"
              variant="primary"
              @click="openModalCreate"
            >
              Novo domínio
            </BaseButton>
        </div>
      </div>

      <div v-else>
        <div style="display: flex" class="mt-3">
          <b-skeleton width="40px" height="40px" style="border-radius: 10px">
          </b-skeleton>
          <div class="d-flex" style="gap: 15px; margin-left: 790px">
            <b-skeleton
              width="141px"
              height="50px"
              style="border-radius: 10px"
            ></b-skeleton>
            <b-skeleton
              width="168px"
              height="50px"
              style="border-radius: 10px"
            ></b-skeleton>
          </div>
        </div>
      </div>

      <!-- <div class="header-table">
        <BaseButton
          v-if="!isMobile && $store.getters.recursos.ndomain && $store.getters.recursos.ndomain <= total"
          class="nova-pagina-disable"
          variant="primary"
          disabled
        >
          Limite de domínios atingido
        </BaseButton>
      </div> -->

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block"> ID </b-col>

        <b-col cols="4" class="d-none d-md-block"> Domínio </b-col>

        <b-col cols="3" class="d-none d-md-block"> Atualizado em </b-col>

        <b-col cols="2" class="d-none d-md-block"> Status </b-col>

        <b-col cols="2" class="d-none d-md-block">Ações</b-col>
      </b-row>

      <!-- BODY -->
      <template v-if="!loading && dominios.length > 0 && !isMobile">
        <b-row class="Table-body" v-for="item in dominios" :key="item.id">
          <!-- id -->
          <b-col class="id" cols="1">
            <span style="font-size: smaller; font-weight: 400; color: var(--gray03);">#{{ item.id }}</span>
          </b-col>

          <!--título -->
          <b-col cols="4">
            {{ item.domain }} <br>
            <span style="font-size: smaller; font-weight: 400; color: var(--gray03);">Tipo {{item.type_selected}} {{ item.type_selected === 'A' ? '- ' + item.ip_selected : '' }}</span>
          </b-col>

          <b-col cols="3">
            {{ item.updated_at | datetimem }}
          </b-col>

          <b-col cols="2">
            <div style="position: relative; right: 32px;" :class="getStatusClass(item.status)" id="status">
              <div v-if="item.status === -5">Tentou por mais de 1 mês</div>
              <div v-if="item.status === -4">Não é um dominio</div>
              <div v-if="item.status === -3">Dominio interno</div>
              <div v-if="item.status === -2">Caracteres especiais</div>
              <div v-if="item.status === -1">Faltando argumentos</div>
              <div v-if="item.status === 0">Aguardando</div>
              <div v-if="item.status === 1">Dominio não propagado</div>
              <div v-if="item.status === 2">Erro ao gerar o certificado</div>
              <div v-if="item.status === 3">Erro ao gerar o Virtual Host</div>
              <div v-if="item.status === 4">
                24 horas pra validar certificado
              </div>
              <div v-if="item.status === 6">Domínio OK</div>
            </div>
          </b-col>

          <b-col cols="2" class="d-flex">
            <BaseButton
              style="padding-left: 0 !important"
              variant="text-info"
              @click="openModalEdit(item)"
            >
              Editar
            </BaseButton>
            <BaseButton
              @click="deleteDomain(item.id)"
              style="padding-left: 0 !important"
              variant="text-danger"
            >
              Deletar
            </BaseButton>
          </b-col>
        </b-row>
      </template>
      <div v-if="loading" id="skeleton" style="margin-top: 60px">
        <div v-for="(n, index) in 8" :key="index">
          <div>
            <div class="d-flex" style="gap: 165px; margin-left: 15px">
              <b-skeleton width="30px" height="20px"></b-skeleton>
              <b-skeleton width="120px" height="20px"></b-skeleton>
              <b-skeleton width="120px" height="20px"></b-skeleton>
              <b-skeleton width="100px" height="20px"></b-skeleton>
              <b-skeleton
                width="60px"
                height="20px"
                style="margin-left: -80px"
              ></b-skeleton>
              <b-skeleton
                width="60px"
                height="20px"
                style="margin-left: -145px"
              ></b-skeleton>
            </div>
            <b-skeleton
              width="80px"
              height="20px"
              style="margin-left: 780px"
            ></b-skeleton>
          </div>
          <br />
        </div>
      </div>

      <b-row
        v-if="!loading && dominios.length === 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum domínio foi encontrado</p>
      </b-row>
      <b-row>
        <b-col>
          <Paginate
            v-if="dominios.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :disabled="true"
          />
        </b-col>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <CriarDominio @update="fetchDomains(1)" />
      <InstrucaoDominio />
      <DominioModal />
      <EditarDominio @update="fetchDomains(1)" :domain="selected_domain" />
      <img src="../../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
    </div>
  </div>
</template>
<script>
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();
import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import Vue from "vue";
import CriarDominio from "./CriarDominio";
import EditarDominio from "./EditarDominioModal.vue";
import DominioModal from "./DominioModal.vue";
import InstrucaoDominio from "./InstrucaoDominio.vue";

export default {
  name: "ListagemVendas",
  components: {
    Paginate,
    BaseHeader,
    CriarDominio,
    DominioModal,
    InstrucaoDominio,
    EditarDominio,
  },
  data() {
    return {
      navigation: [{ link: "Gestão de domínios", to: this.$route.fullPath }],
      paginas: [],
      dominios: [],
      status: true,
      leads: [],
      search: null,
      loading: true,
      totalPages: 0,
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      filters: {},
      client: {
        width: 0,
      },
      selected_domain: {},
      recursos: this.$store.getters.recursos,
    };
  },
  methods: {
    openModalCreate() {
      this.$bvModal.show("modal-domain");
    },
    openModalEdit(domain) {
      this.$bvModal.show("modal-edit-domain");
      this.selected_domain = domain;
    },
    openModalInstruction() {
      this.$bvModal.show("modal-domain-instruction");
    },

    getStatusClass(status) {
      if (status === 0) {
        return 'status-dominio-wait';
      } else if (status >= -5 && status <= 3) {
        return 'status-dominio-not';
      } else if (status === 4) {
        return 'status-dominio-wait';
      } else if (status === 6) {
        return 'status-dominio-ok';
      } else {
        return '';
      }
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    deleteDomain(id) {
      Vue.swal({
        title: "Domínios",
        text: `Deseja deletar o domínio?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceDomain
            .destroy({ id: id })
            .then(() => {
              this.fetchDomains();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    fetchDomains(page) {
      serviceDomain
        .read({ id: `/list?page=${page ? page : 1}` })
        .then((resp) => {
          this.dominios = resp.data;
          this.total = resp.total;
          this.pagination.totalPages = resp.last_page;
          this.pagination.currentPage = resp.current_page;
          this.pagination.perPage = resp.per_page;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reloadList() {
      this.loading = true;
      this.fetchDomains();
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchDomains(page);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchDomains(this.search);
    },
    limitMessage() {
      if (!this.limitDomains) {
        return
      }
      this.$grToast.toast(
        "Entre em contato com o suporte",
        {
          title: "Limite de domínios atingido",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    this.fetchDomains();
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    limitDomains() {
      if (!this.$store.getters.recursos.hasOwnProperty('ndomain')) {
        return false
      }
      if (this.$store.getters.recursos.ndomain == 0) {
        return false
      }
      return this.total >= +this.$store.getters.recursos.ndomain
    }
  },
};
</script>

<style lang="scss" scoped>
.paginas-menu {
  border-radius: 10px;
  border: none;
}

.status-dominio-ok{
  background-color: var(--greenn2);
  border-radius: 10px;
  text-align: center;
  padding: 5px 0px;
  div{
    color:var(--greenn);
  }
}
.status-dominio-wait{
  background-color: var(--orange2);
  border-radius: 10px;
  text-align: center;
  padding: 5px 0px;
  div{
    color:var(--orange);
  }
}
.status-dominio-not{
  background-color: var(--red2);
  border-radius: 10px;
  text-align: center;
  padding: 5px 0px;
  div{
    color:var(--red);
  }
}
.margin {
  height: 60px !important;
}
input {
  margin-bottom: 0 !important;
}
.paginas-descricao-opt {
  color: var(--greenn);
}
.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray01);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  line-height: 20px;
  padding-right: 8px;
  margin: 0;
  border-radius: 3px;
}
.paginas-descricao {
  padding: 30px 30px;
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.paginas-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
}
.img-conteudo {
  width: 100%;
  object-fit: contain;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  position: relative;
}
.img-icon {
  width: 12px;
  height: 13.5px;
}
.display-flex2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -15px;
}
.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.nova-pagina {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  padding: 20px 40px;
  gap: 10px;
  background: var(--greenn);
  border-radius: 10px;
  border: none;
}
.box-total {
  justify-content: center;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;

  .btn-reload {
    width: 43px !important;
    height: 43px !important;
    padding: 0px !important;
  }
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 37px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  position: relative;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
    margin-bottom: 10px;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
  .paginas-conteudo-container {
    grid-template-columns: 1fr;
  }
  .nova-pagina {
    margin: 5px;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
  padding-left: 23px;
}
.Table-body::before {
  left: 0;
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}
.ativo {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-color: var(--greenn);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 5px;
    left: -15px;
  }
}
.aguardando {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-color: #ffc107;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 5px;
    left: -15px;
  }
}

.instrucao {
  background-color: var(--white-medium);
  color: var(--gray01);
}
</style>
